import React from 'react'
import { NavLink } from "react-router-dom";
import './Navbar.css'
import {useRecoilValue} from 'recoil'
import { activeLanguageState } from '../state.js'
import {navbarContent} from '../content/navbarCont.js'

export default function Navbar() {
	const activeLanguage = useRecoilValue(activeLanguageState)
	const {about, tts, sst, vidToText} = navbarContent[activeLanguage]
	return (
		<nav className="fluidFlex" style={styles.navStyles}>
		<NavLink style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/"}>
		<h3>{about}</h3>
		</NavLink>

		<NavLink
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/tts"}>
		<h3>{tts}</h3>
		</NavLink>

		<NavLink
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/sst"}>
		<h3>{sst}</h3>
		</NavLink>

		<NavLink
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/vtt"}>
		<h3>{vidToText}</h3>
		</NavLink>

		
		</nav>
		)
}

const styles = {
	navStyles: {
		// marginBottom: '2em',
		// marginTop: '2em',
		textAlign:'left',
	},
	active: {
		backgroundColor: "#f1f1f1",
		color: 'magenta'
		// flex: '1 1 200px',
	},
default: {
	textDecoration: "underline",
	color: '#f1f1f1',
	// flex: '1 1 200px',
}
}
