
export const vttContent = {
	en: {
		metaTitle: 'Video to text generator',
		metaDescription: 'Transcribe video to text',
		metaKeywords: 'video to text, , generate subtitles, text from video, generate voice over for video, transcribe youtube, create subtitles for youtube',
		title:'Video to text',
		subtitle:'Transcribe video to text',
		description:'It will generate a text file from your video file.',
		provider:'Provider: OpenAI',

    selectFile: '1. Provide a link to your video file',
    maxFileSize: "It should be a direct link to the video itself.",
    supportedFormats: "We support most of the video hosting platforms like YouTube, Vimeo, etc...",
    supLangs:'Supported languages for the transcribing',
    supportedLanguages: 'Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, Welsh',
    costDescription: 'The cost of video to text conversion is {costPerMinute/100}€ for 1 minute of video. It rounds up. You will see the total cost after analyzing the file.',
    analyzeBtn: "Analyze",
    durationOfAudio: "Duration of your video is",
    vttPrice:'The cost will be: {vttCost/100}',
    minutes: "minutes",
    seconds: "seconds.",


    uploadFile:"2. Upload file to the server",
    uploadFileBeforePay:"Upload file to the server before transcribing it.",

    outputEmailTitle: '4. Output email',
    emailInstructions: 'Please provide the email to send a link to the generated text file. Make sure it\'s the correct email.',
    emailPlaceholder: 'Enter your email here',
    verifyEmailPlaceholder: 'Verify your email here',
    emailWarning: 'Warning: emails do not match',


    transcribe:"5. Run the transcription",
    transcribeInstructions: 'Click the button below to transcribe your audio file.',
    paymentInfo: 'You will be redirected to the secure payment processing page. Your total is {vttCost/100}€.',
    successfulCheckoutInfo: 'Upon successful checkout, you will be redirected to the confirmation page where you will also be able to download the generated text file.',
    buttonText: 'Pay and Transcribe',
    uploadFilePrompt: 'You need to upload an audio file before being able to proceed.',
    outputFormatTitle: '3. Output format for your text file',
    currentlySelectedOutputFormat: 'Currently selected: ',
  },
  jp: {
  metaTitle: '音声からテキストへの変換',
  metaDescription: 'オーディオをテキストに転記',
  metaKeywords: 'stt、音声からテキスト、字幕生成、音声からテキスト生成、ナレーション生成',
  title: '音声からテキストへ',
  subtitle: 'オーディオをテキストに転記',
  description: 'オーディオファイルからテキストファイルを生成します。',
  provider: '提供者：OpenAI',

  selectFile: '1. オーディオファイルを選択',
  maxFileSize: 'ファイルサイズは25mbを超えることはできません',
  supportedFormats: '現在サポートされているファイル形式: mp3, mp4, mpeg, mpga, m4a, wav, および webm。',
  supLangs: '対応言語',
  supportedLanguages: 'アフリカーンス語、アラビア語、アルメニア語、アゼルバイジャン語、ベラルーシ語、ボスニア語、ブルガリア語、カタロニア語、中国語、クロアチア語、チェコ語、デンマーク語、オランダ語、英語、エストニア語、フィンランド語、フランス語、ガリシア語、ドイツ語、ギリシャ語、ヘブライ語、ヒンディー語、ハンガリー語、アイスランド語、インドネシア語、イタリア語、日本語、カンナダ語、カザフ語、韓国語、ラトビア語、リトアニア語、マケドニア語、マレー語、マラーティー語、マオリ語、ネパール語、ノルウェー語、ペルシャ語、ポーランド語、ポルトガル語、ルーマニア語、ロシア語、セルビア語、スロバキア語、スロベニア語、スペイン語、スワヒリ語、スウェーデン語、タガログ語、タミル語、タイ語、トルコ語、ウクライナ語、ウルドゥ語、ベトナム語、ウェールズ語',
  durationOfAudio: 'オーディオの長さは',
  minutes: '分',
  seconds: '秒',

  costDescription: '音声からテキストへの変換のコストは、1分のオーディオにつき {costPerMinute/100}€ です。切り上げられます。ファイルをアップロード後に合計コストが表示されます。',

  uploadFile: '2. サーバーにファイルをアップロード',
  uploadFileBeforePay: '音声を転記する前にファイルをサーバーにアップロードしてください。',

  outputEmailTitle: '4. 出力先メール',
  emailInstructions: '生成されたテキストファイルへのリンクを送信するためにメールアドレスを提供してください。正しいメールアドレスであることを確認してください。',
  emailPlaceholder: 'メールアドレスをこちらに入力',
  verifyEmailPlaceholder: 'メールアドレスをこちらで確認',
  emailWarning: '警告: メールアドレスが一致しません',

  transcribe: '5. 転記を実行',
  transcribeInstructions: '以下のボタンをクリックしてオーディオファイルを転記してください。',
  paymentInfo: '安全な支払い処理ページにリダイレクトされます。合計金額は {vttCost/100}€ です。',
  successfulCheckoutInfo: '支払いが成功すると、確認ページにリダイレクトされ、生成されたテキストファイルをダウンロードできます。',
  buttonText: '支払いと転記',
  uploadFilePrompt: '続行する前にオーディオファイルをアップロードする必要があります。',
  outputFormatTitle: '3. テキストファイルの出力形式',
  currentlySelectedOutputFormat: '現在の選択: ',
},
es: {
  metaTitle: 'Generador de texto a voz',
  metaDescription: 'Transcribir audio a texto',
  metaKeywords: 'stt, texto a voz, generar subtítulos, texto a partir de voz, generar voz en off',
  title: 'Texto a Voz',
  subtitle: 'Transcribir audio a texto',
  description: 'Generará un archivo de texto a partir de su archivo de audio.',
  provider: 'Proveedor: OpenAI',

  selectFile: '1. Selecciona tu archivo de audio',
  maxFileSize: 'El tamaño del archivo no puede exceder los 25mb',
  supportedFormats: 'Los siguientes formatos de archivo son actualmente compatibles: mp3, mp4, mpeg, mpga, m4a, wav y webm.',
  supLangs: 'Idiomas admitidos',
  supportedLanguages: 'Afrikáans, árabe, armenio, azerí, bielorruso, bosnio, búlgaro, catalán, chino, croata, checo, danés, holandés, inglés, estonio, finlandés, francés, gallego, alemán, griego, hebreo, hindi, húngaro, islandés, indonesio, italiano, japonés, kannada, kazajo, coreano, letón, lituano, macedonio, malayo, marathi, maorí, nepalí, noruego, persa, polaco, portugués, rumano, ruso, serbio, eslovaco, esloveno, español, suajili, sueco, tagalo, tamil, tailandés, turco, ucraniano, urdu, vietnamita, galés.',
  durationOfAudio: 'La duración de tu audio es',
  minutes: 'minutos',
  seconds: 'segundos.',

  costDescription: 'El costo de la conversión de voz a texto es de {costPerMinute/100}€ por 1 minuto de audio. Se redondea hacia arriba. Verás el costo total después de cargar el archivo.',

  uploadFile: '2. Sube el archivo al servidor',
  uploadFileBeforePay: 'Sube el archivo al servidor antes de transcribirlo.',

  outputEmailTitle: '4. Correo electrónico de salida',
  emailInstructions: 'Por favor, proporciona el correo electrónico para enviar un enlace al archivo de texto generado. Asegúrate de que sea el correo electrónico correcto.',
  emailPlaceholder: 'Ingresa tu correo electrónico aquí',
  verifyEmailPlaceholder: 'Verifica tu correo electrónico aquí',
  emailWarning: 'Advertencia: los correos electrónicos no coinciden',

  transcribe: '5. Iniciar la transcripción',
  transcribeInstructions: 'Haz clic en el botón de abajo para transcribir tu archivo de audio.',
  paymentInfo: 'Serás redirigido a la página segura de procesamiento de pagos. Tu total es de {vttCost/100}€.',
  successfulCheckoutInfo: 'Tras realizar el pago con éxito, serás redirigido a la página de confirmación donde también podrás descargar el archivo de texto generado.',
  buttonText: 'Pagar y Transcribir',
  uploadFilePrompt: 'Necesitas cargar un archivo de audio antes de poder proceder.',
  outputFormatTitle: '3. Formato de salida para tu archivo de texto',
  currentlySelectedOutputFormat: 'Actualmente seleccionado: ',
},
ru: {
  metaTitle: 'Генератор текста из речи',
  metaDescription: 'Транскрибация аудио в текст',
  metaKeywords: 'stt, речь в текст, создание субтитров, текст из звука, генерация голоса',
  title: 'Речь в текст',
  subtitle: 'Транскрибация аудио в текст',
  description: 'Создаст текстовый файл из вашего аудиофайла.',
  provider: 'Поставщик: OpenAI',

  selectFile: '1. Выберите ваш аудиофайл',
  maxFileSize: 'Размер файла не может превышать 25 МБ',
  supportedFormats: 'Поддерживаемые форматы файлов: mp3, mp4, mpeg, mpga, m4a, wav, и webm.',
  supLangs: 'Поддерживаемые языки',
  supportedLanguages: 'Африкаанс, арабский, армянский, азербайджанский, белорусский, боснийский, болгарский, каталанский, китайский, хорватский, чешский, датский, голландский, английский, эстонский, финский, французский, галисийский, немецкий, греческий, иврит, хинди, венгерский, исландский, индонезийский, итальянский, японский, каннада, казахский, корейский, латышский, литовский, македонский, малайский, маратхи, маори, непальский, норвежский, персидский, польский, португальский, румынский, русский, сербский, словацкий, словенский, испанский, суахили, шведский, тагалог, тамильский, тайский, турецкий, украинский, урду, вьетнамский, валлийский',
  durationOfAudio: 'Продолжительность вашего аудио',
  minutes: 'минут',
  seconds: 'секунд.',

  costDescription: 'Стоимость конвертации речи в текст составляет {costPerMinute/100}€ за 1 минуту аудио. Стоимость округляется вверх. Вы увидите общую стоимость после загрузки файла.',

  uploadFile: '2. Загрузите файл на сервер',
  uploadFileBeforePay: 'Загрузите файл на сервер перед его транскрибацией.',

  outputEmailTitle: '4. Электронная почта для вывода',
  emailInstructions: 'Пожалуйста, укажите адрес электронной почты для отправки ссылки на сгенерированный текстовый файл. Убедитесь, что это правильная почта.',
  emailPlaceholder: 'Введите свою почту здесь',
  verifyEmailPlaceholder: 'Подтвердите свою почту здесь',
  emailWarning: 'Предупреждение: почты не совпадают',

  transcribe: '5. Запустите транскрибацию',
  transcribeInstructions: 'Нажмите кнопку ниже, чтобы транскрибировать ваш аудиофайл.',
  paymentInfo: 'Вы будете перенаправлены на страницу безопасной обработки платежей. Ваш общий счет {vttCost/100}€.',
  successfulCheckoutInfo: 'После успешной оплаты вы будете перенаправлены на страницу подтверждения, где сможете скачать сгенерированный текстовый файл.',
  buttonText: 'Оплатить и транскрибировать',
  uploadFilePrompt: 'Вам нужно загрузить аудиофайл, прежде чем продолжить.',
  outputFormatTitle: '3. Формат вывода для вашего текстового файла',
  currentlySelectedOutputFormat: 'Текущий выбранный: ',
},
zh: {
  metaTitle: '语音转文字生成器',
  metaDescription: '将音频转录为文字',
  metaKeywords: 'stt, 语音转文字, 生成字幕, 文字生成声音',
  title: '语音转文字',
  subtitle: '将音频转录为文字',
  description: '将从您的音频文件生成一个文本文件。',
  provider: '提供商：OpenAI',

  selectFile: '1. 选择您的音频文件',
  maxFileSize: '文件大小不能超过25MB',
  supportedFormats: '当前支持的文件格式：mp3、mp4、mpeg、mpga、m4a、wav 和 webm。',
  supLangs: '支持的语言',
  supportedLanguages: '南非荷兰语、阿拉伯语、亚美尼亚语、阿塞拜疆语、白俄罗斯语、波斯尼亚语、保加利亚语、加泰罗尼亚语、中文、克罗地亚语、捷克语、丹麦语、荷兰语、英语、爱沙尼亚语、芬兰语、法语、加利西亚语、德语、希腊语、希伯来语、印地语、匈牙利语、冰岛语、印尼语、意大利语、日语、卡纳达语、哈萨克语、韩语、拉脱维亚语、立陶宛语、马其顿语、马来语、马拉地语、毛利语、尼泊尔语、挪威语、波斯语、波兰语、葡萄牙语、罗马尼亚语、俄语、塞尔维亚语、斯洛伐克语、斯洛文尼亚语、西班牙语、斯瓦希里语、瑞典语、塔加洛语、泰米尔语、泰语、土耳其语、乌克兰语、乌尔都语、越南语、威尔士语',
  durationOfAudio: '您的音频时长为',
  minutes: '分钟',
  seconds: '秒',

  costDescription: '语音转文字转换的费用为每分钟音频{costPerMinute/100}€。它会四舍五入。在上传文件后，您将看到总费用。',

  uploadFile: '2. 将文件上传到服务器',
  uploadFileBeforePay: '在转录之前将文件上传到服务器。',

  outputEmailTitle: '4. 输出电子邮件',
  emailInstructions: '请提供电子邮件地址，以便发送生成的文本文件的链接。请确保这是正确的电子邮件。',
  emailPlaceholder: '在此输入您的电子邮件',
  verifyEmailPlaceholder: '在此验证您的电子邮件',
  emailWarning: '警告：电子邮件地址不匹配',

  transcribe: '5. 运行转录',
  transcribeInstructions: '单击下面的按钮，以转录您的音频文件。',
  paymentInfo: '您将被重定向到安全的支付处理页面。您的总费用为{vttCost/100}€。',
  successfulCheckoutInfo: '成功付款后，您将被重定向到确认页面，您还可以下载生成的文本文件。',
  buttonText: '支付并转录',
  uploadFilePrompt: '您需要上传音频文件才能继续。',
  outputFormatTitle: '3. 您的文本文件的输出格式',
  currentlySelectedOutputFormat: '当前选择：',
},
ar: {
  metaTitle: 'محول الصوت إلى نص',
  metaDescription: 'نقل الصوت إلى نص',
  metaKeywords: 'stt، تحويل الصوت إلى نص، إنشاء ترجمات، نص من الصوت، إنشاء تسجيل صوتي',
  title: 'تحويل الصوت إلى نص',
  subtitle: 'نقل الصوت إلى نص',
  description: 'سيقوم بإنشاء ملف نصي من ملف الصوت الخاص بك.',
  provider: 'المزود: OpenAI',

  selectFile: '١. حدد ملف الصوت الخاص بك',
  maxFileSize: 'لا يمكن أن يتجاوز حجم الملف 25 ميغابايت',
  supportedFormats: 'التنسيقات الملفات المدعومة حاليًا: mp3، mp4، mpeg، mpga، m4a، wav، و webm.',
  supLangs: 'اللغات المدعومة',
  supportedLanguages: 'الأفريكانية، العربية، الأرمينية، الأذربيجانية، البيلاروسية، البوسنية، البلغارية، الكتالانية، الصينية، الكرواتية، التشيكية، الدانماركية، الهولندية، الإنجليزية، الإستونية، الفنلندية، الفرنسية، الجاليكية، الألمانية، اليونانية، العبرية، الهندية، الهنغارية، الأيسلندية، الإندونيسية، الإيطالية، اليابانية، الكانادا، الكازاخية، الكورية، اللاتفية، اللتوانية، المقدونية، الملايو، الماراثى، الماوري، النيبالية، النرويجية، الفارسية، البولندية، البرتغالية، الرومانية، الروسية، الصربية، السلوفاكية، السلوفينية، الإسبانية، السواحلية، السويدية، التاغالوغية، التاميل، التايلاندية، التركية، الأوكرانية، الأردية، الفيتنامية، الويلزية',
  durationOfAudio: 'مدة صوتك هي',
  minutes: 'دقائق',
  seconds: 'ثوانٍ',

  costDescription: 'تكلفة تحويل الصوت إلى نص هي {costPerMinute/100}€ لدقيقة واحدة من الصوت. يتم تقريبها. سترى التكلفة الإجمالية بعد تحميل الملف.',

  uploadFile: '٢. قم بتحميل الملف إلى الخادم',
  uploadFileBeforePay: 'قم بتحميل الملف إلى الخادم قبل نسخه. ',

  outputEmailTitle: '٤. البريد الإلكتروني للإخراج',
  emailInstructions: 'يرجى تقديم البريد الإلكتروني لإرسال رابط لملف النص المولد. تأكد من أنه البريد الإلكتروني الصحيح.',
  emailPlaceholder: 'أدخل بريدك الإلكتروني هنا',
  verifyEmailPlaceholder: 'تحقق من بريدك الإلكتروني هنا',
  emailWarning: 'تحذير: عناوين البريد الإلكتروني غير متطابقة',

  transcribe: '٥. تشغيل التسجيل',
  transcribeInstructions: 'انقر على الزر أدناه لتسجيل الصوت الخاص بك.',
  paymentInfo: 'سيتم توجيهك إلى صفحة معالجة الدفع الآمنة. إجماليك هو {vttCost/100}€.',
  successfulCheckoutInfo: 'بعد نجاح عملية الدفع، سيتم توجيهك إلى صفحة التأكيد حيث يمكنك أيضًا تنزيل ملف النص المولد.',
  buttonText: 'ادفع وسجل',
  uploadFilePrompt: 'تحتاج إلى تحميل ملف صوت قبل الاستمرار.',
  outputFormatTitle: '٣. تنسيق الملف النصي الخاص بك',
  currentlySelectedOutputFormat: 'التنسيق المحدد حاليًا: ',
}
};
