import React, { useState } from 'react';
import './Slider.css'
import {sliderContent} from '../content/sliderCont.js'
import {useRecoilValue} from 'recoil'
import { activeLanguageState } from '../state.js'

const SliderComponent = ({setSpeed}) => {
  const activeLanguage = useRecoilValue(activeLanguageState)
  // Set default value to 1
  const defaultValue = 1;
  // State to manage slider value
  const [sliderValue, setSliderValue] = useState(defaultValue);


const {speed, buttonLabel} = sliderContent[activeLanguage]
  // Function to handle slider value change
  const handleSliderChange = (event) => {
    setSliderValue(parseFloat(event.target.value));
    setSpeed(event.target.value)

  };

  // Function to reset slider value to default
  const resetToDefault = () => {
    setSliderValue(defaultValue);
    setSpeed(defaultValue)
  };

  return (
    <div className='slider'>
      <label>
        {speed} {sliderValue.toFixed(2)}
        <input
          type="range"
          min={0.25}
          max={4}
          step={0.01}
          value={sliderValue}
          onChange={handleSliderChange}
        />
      </label>

      <button onClick={resetToDefault}>{buttonLabel}</button>
    </div>
  );
};

export default SliderComponent;
