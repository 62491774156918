import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Stripe from './components/Stripe.js'
import {useState} from 'react'
// language state
import { useRecoilState } from 'recoil'
import { activeLanguageState } from './state.js'

import logo from './logo.svg';
import './App.css';

import {homepageContent as homepage} from './content/homepage.js';
import ServiceDescription from './components/serviceDescription.js';
import Footer from './components/Footer.js';
import Navbar from './components/Navbar.js'
import PaymentSuccess from "./views/payment_success";
import SstPaymentSuccess from "./views/sst_payment_success";
import VttPaymentSuccess from "./views/vtt_payment_success";
import PaymentError from "./views/payment_error";


// const activeLanguage='en'

function App() {
  const [activeLanguage, setActiveLanguage] = useRecoilState(activeLanguageState)
  return (
    <div className="App">
    <header className="App-header">

    <div>
    <h1>{homepage[activeLanguage].title}</h1>
    <h2>{homepage[activeLanguage].slogan}</h2>
    <p>AIツールのセット、リクエストに応じて利用可能</p>
    </div>
    {/*// add selector dropdown with 6 language options*/}
    <div >
    <select value={activeLanguage} onChange={(e)=>setActiveLanguage(e.target.value)}>
    <option value="en">English</option>
    <option value="jp">日本語</option>
    <option value="es">Español</option>
    <option value="ru">Русский</option>
    <option value="zh">中文</option>
    <option value="ar">عربى</option>
    </select>
    </div>

    </header>
    <Router>
    <Navbar/>
    <main>
    <Routes>
    <Route path='/' element={<ServiceDescription/>}/>
    <Route path='/tts' element={<Stripe service={'tts'}/>}/>
    <Route path="/payment/success" element={<PaymentSuccess />}/>
    <Route path='/sst' element={<Stripe service={'sst'}/>}/>
    <Route path='/sst-payment/success' element={<SstPaymentSuccess />}/>
    <Route path='/vtt' element={<Stripe service={'vtt'}/>}/>
    <Route path='/vtt-payment/success' element={<VttPaymentSuccess />}/>
    <Route path="/payment/error" element={<PaymentError />}/>

    </Routes>
    </main>
    </Router>
    <Footer/>
    </div>
    );
}

export default App;
