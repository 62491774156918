export const serviceDescriptionContent = {
	en:{
		title:'About our service',
		description:[
			"Our service is designed to bring a way to use the latest AI tools and APIs without any hassle of coding or subscriptions. You can use it only when you need it. There are not recurring fees.",

			`一 On-Demand:\nAccess AI tools anytime when you need it. No registration needed.`,

			"二 Pay-Per-Use:\nOnly pay for what you use. No subscriptions and monthly plans",

			"三 Global Accessibility:\nAvailable anywhere in many languages.",

			"四 Security and Privacy:\nWe don't collect or store any of your data.",

			"五 User-Friendly Interface:\nEasy steps for each functionality.",

			"六 Constant Evolution:\nNew tools are added regularly.",

			"Discover a flexible, accessible, and secure way to engage with AI technology tailored to your needs and budget."
			]
	},
jp: {
  title: 'サービスについて',
  description: [
    "必要な時だけ最新のAIツールとAPIを手間なく利用できるサービスです。継続的な料金はありません。",
    "一 オンデマンド:\n必要な時にいつでもAIツールにアクセス。登録不要。",
    "二 ペイパーユース:\n使用した分だけ支払い。定額プランやサブスクリプションは不要。",
    "三 グローバルアクセシビリティ:\n多言語でどこでも利用可能。",
    "四 セキュリティとプライバシー:\nお客様のデータを収集・保存しません。",
    "五 ユーザーフレンドリーなインターフェース:\n各機能に簡単なステップ。",
    "六 継続的な進化:\n定期的に新しいツールを追加。",
    "AI技術を活用する柔軟で安全な新しい方法を発見してください。"
  ]
},
es: {
  title: 'Acerca de nuestro servicio',
  description: [
    "Servicio para usar herramientas y APIs de AI sin complicaciones de codificación o suscripciones. Úsalo solo cuando lo necesites. Sin tarifas recurrentes.",
    "一 A Demanda:\nAccede a herramientas AI cuando lo necesites. Sin registro.",
    "二 Pago Por Uso:\nSolo paga por lo que usas. Sin suscripciones ni planes mensuales.",
    "三 Accesibilidad Global:\nDisponible en cualquier lugar en varios idiomas.",
    "四 Seguridad y Privacidad:\nNo recopilamos ni almacenamos tus datos.",
    "五 Interfaz Amigable:\nPasos sencillos para cada funcionalidad.",
    "六 Evolución Constante:\nSe añaden nuevas herramientas regularmente.",
    "Descubre una forma flexible, accesible y segura de interactuar con la tecnología AI."
  ]
},
ru: {
  title: 'О нашем сервисе',
  description: [
    "Сервис для использования AI инструментов и API без кодирования или подписок. Используйте только когда нужно. Без повторяющихся платежей.",
    "一 По Требованию:\nДоступ к AI инструментам, когда вам это нужно. Без регистрации.",
    "二 Оплата По Использованию:\nПлатите только за то, что используете. Без подписок и месячных планов.",
    "三 Глобальная Доступность:\nДоступно везде на многих языках.",
    "四 Безопасность и Конфиденциальность:\nМы не собираем и не храним ваши данные.",
    "五 Дружественный Интерфейс:\nПростые шаги для каждой функции.",
    "六 Постоянное Развитие:\nРегулярно добавляются новые инструменты.",
    "Исследуйте гибкий, доступный и безопасный способ взаимодействия с технологией AI."
  ]
},
zh:{
  title:'关于我们的服务',
  description:[
    "我们的服务旨在提供一种使用最新AI工具和API的便捷方式，无需编程或订阅。只需在需要时使用。没有重复费用。",

    "一 按需服务:\n随时根据需要访问AI工具。无需注册。",

    "二 按次付费:\n仅为所用内容付费。无需订阅和月度计划",

    "三 全球可及:\n在多种语言中随处可用。",

    "四 安全与隐私:\n我们不收集或存储您的任何数据。",

    "五 用户友好界面:\n每项功能都简单易用。",

    "六 持续进化:\n定期添加新工具。",

    "发现一种灵活、易用、安全的方式，按您的需求和预算使用AI技术。"
    ]
},
ar:{
  title:'حول خدمتنا',
  description:[
    "تم تصميم خدمتنا لتوفير طريقة استخدام أحدث أدوات وواجهات برمجة التطبيقات الذكاء الاصطناعي بدون الحاجة للترميز أو الاشتراكات. يمكنك استخدامها فقط عند الحاجة. لا توجد رسوم متكررة.",

    "一 الطلب المباشر:\nالوصول إلى أدوات الذكاء الاصطناعي في أي وقت تحتاجها. لا حاجة للتسجيل.",

    "二 الدفع حسب الاستخدام:\nادفع فقط مقابل ما تستخدمه. لا اشتراكات ولا خطط شهرية",

    "三 سهولة الوصول العالمية:\nمتاحة في أي مكان بعدة لغات.",

    "四 الأمان والخصوصية:\nنحن لا نجمع أو نخزن أي من بياناتك.",

    "五 واجهة المستخدم الودودة:\nخطوات سهلة لكل وظيفة.",

    "六 التطور المستمر:\nيتم إضافة أدوات جديدة بانتظام.",

    "اكتشف طريقة مرنة وميسرة وآمنة للتفاعل مع تكنولوجيا الذكاء الاصطناعي وفقًا لاحتياجاتك وميزانيتك."
    ]
},
}
