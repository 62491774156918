import axios from '../configs/axios.js'

const GenerateTTS=async (email,name,ok,payment_intent,status,input, emailTo, outputFormat, voice, speed)=>{
	const data={voice:voice.toLowerCase(), input, filename:`Generated_by_AI_toolbox_for_${email}_${payment_intent}`, email:emailTo, format:outputFormat, speed}
	try{
		let generated = await axios.post('/services/tts',data)
		console.log(generated)
		return generated
	}catch(e){

	}

}
export {GenerateTTS}