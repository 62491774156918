export const ttsSuccess = {
	en:{
		title: 'Success / 終了した',
		voiceover: 'Your text file is created and sent to email@email.com.',
		check: 'Please check your inbox or spam folder.',
		link: 'Here is the direct link to download your file.',
		download: 'Right-click and select "Download linked file as..."',
		expire: 'The link will expire in 10 days and the files will be removed from our servers. Please download it to keep it.',
		thanks: 'Thank you for using our service!',
	},
	jp:{
		title:'成功 / 終了した' ,
		voiceover:'あなたのテキストファイルが作成され、email@email.com に送信されました。' ,
		check:'受信トレイまたはスパムフォルダをご確認ください。' ,
		link:'こちらはファイルをダウンロードする直接のリンクです。' ,
		download:'右クリックして「リンク先を別名でダウンロード」を選択してください。' ,
		expire:'リンクは10日で期限切れとなり、ファイルはサーバーから削除されます。保持するためにダウンロードしてください。' ,
		thanks:'当サービスをご利用いただきありがとうございます！' ,
	},
	es:{
		title:'Éxito / Completado' ,
		voiceover:'Se ha creado su archivo de texto y se ha enviado a email@email.com.' ,
		check:'Por favor, revise su bandeja de entrada o carpeta de spam.' ,
		link:'Aquí tiene el enlace directo para descargar su archivo.' ,
		download:'Haga clic derecho y seleccione "Guardar enlace como..."' ,
		expire:'El enlace caducará en 10 días y los archivos se eliminarán de nuestros servidores. Por favor, descárguelo para conservarlo.' ,
		thanks:'¡Gracias por utilizar nuestro servicio!' ,
	},
	ru:{
		title: 'Успех / Завершено',
		voiceover: 'Ваш текстовый файл создан и отправлен на email@email.com.',
		check: 'Пожалуйста, проверьте свой почтовый ящик или папку со спамом.',
		link: 'Вот прямая ссылка для скачивания вашего файла.',
		download: 'Щелкните правой кнопкой мыши и выберите "Скачать связанный файл как..."',
		expire: 'Ссылка устареет через 10 дней, и файлы будут удалены с наших серверов. Пожалуйста, загрузите его, чтобы сохранить.',
		thanks: 'Спасибо за использование нашего сервиса!',
	},
	zh:{
		title:'成功 / 完成' ,
		voiceover:'您的文本文件已创建并发送至email@email.com。' ,
		check:'请检查您的收件箱或垃圾邮件文件夹。' ,
		link:'这是直接下载文件的链接。' ,
		download:'右键单击并选择“另存为链接文件...”' ,
		expire:'链接将在10天后过期，文件将从我们的服务器上删除。请下载以保存文件。' ,
		thanks:'感谢使用我们的服务！' ,
	},
	ar:{
		title:'نجاح / تم' ,
		voiceover:'تم إنشاء ملف النص الخاص بك وإرساله إلى email@email.com. ' ,
		check:'يرجى التحقق من صندوق البريد الوارد أو مجلد البريد العشوائي.' ,
		link:'إليك الرابط المباشر لتنزيل ملفك.' ,
		download:'انقر بزر الماوس الأيمن وحدد "تنزيل الملف المرتبط باسم..."' ,
		expire:'سينتهي الرابط في غضون 10 أيام وسيتم إزالة الملفات من خوادمنا. يرجى تنزيله للحفاظ عليه.' ,
		thanks:'شكرًا لاستخدام خدمتنا!' ,
	}
}