import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {useStripe} from '@stripe/react-stripe-js';
import axios from '../configs/axios.js'
import Helmet from 'react-helmet';
import {useRecoilValue} from 'recoil'
import { activeLanguageState } from '../state.js'
import {sstContent} from '../content/sstContent.js'

export default function Sst() {
	const activeLanguage = useRecoilValue(activeLanguageState)

	const {metaTitle, metaDescription, metaKeywords, title, subtitle, description, provider, selectFile, maxFileSize, supportedFormats, costDescription, durationOfAudio, minutes, seconds, uploadFile, uploadFileBeforePay, transcribe, outputEmailTitle, emailInstructions, emailPlaceholder, verifyEmailPlaceholder, emailWarning, supLangs, supportedLanguages, 	transcribeInstructions, paymentInfo, successfulCheckoutInfo, buttonText, uploadFilePrompt, outputFormatTitle, currentlySelectedOutputFormat
} = sstContent[activeLanguage]

const [sstCost, setSstCost] = useState(0)
	const costPerMinute = 99 // cents


	const [selectedFile, setSelectedFile] = useState(null);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [audioDuration, setAudioDuration] = useState(0);

	const [email, setEmail] = useState('')
	const [emailVer, setEmailVer] = useState('')
	const [fileName, setFileName] = useState('')
	const [filePath, setFilePath] = useState('')
	const [outputFormat, setOutputFormat] = useState('')

	useEffect(()=>{
		if(audioDuration>0){
		const actualCost = Math.ceil(audioDuration*costPerMinute/100)
		setSstCost(actualCost>99?actualCost:99)
		}
	},[audioDuration])
  // Function to handle file selection
	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
 // Retrieve and set the audio duration
		if (file) {
			const audio = new Audio();
			audio.src = URL.createObjectURL(file);
			audio.onloadedmetadata = () => {
				setAudioDuration(Math.ceil(audio.duration));
        URL.revokeObjectURL(audio.src); // Free up resources
      };
    }
  };

  // Function to upload file to the server
  const handleUpload = async () => {
  	try {
  		if (!selectedFile) {
  			console.error('Please select a file');
  			return;
  		}
      // Create a FormData object to append the file
  		const formData = new FormData();
  		formData.append('file', selectedFile);
      // Make a POST request using Axios
  		const response = await axios.post('/services/sst/uploadaudio', formData, {
  			headers: {
  				'Content-Type': 'multipart/form-data',
  			},
  			onUploadProgress: (ProgressEvent) => {
  				setUploadProgress( Math.round( (ProgressEvent.loaded/ProgressEvent.total)*100 ) );

  			},
  		});

      // Handle the response as needed (e.g., update state)
  		console.log('File uploaded successfully', response.data.file.filename, response.data.file.path);
  		setFileName(response.data.file.filename)
  		setFilePath(response.data.file.path)

      // Reset file-related state
  		setSelectedFile(null);
		// setUploadProgress(0);

      // Add further logic based on the response from the server
  	} catch (error) {
  		console.error('Error uploading file', error);
      // Handle the error as needed
  	}
  };



  const navigate = useNavigate();
  const stripe = useStripe();

  const createCheckoutSession = async (products) => {
  	try {
  		const response = await axios.post(`/payment/sst/create-checkout-session`, { products });
  		return response.data.ok
  		? (localStorage.setItem('sessionId', JSON.stringify(response.data.sessionId)),
  			redirect(response.data.sessionId))
  		: navigate('/payment/error');
  	} catch (error) {
  		navigate('/payment/error');
  	}
  };

  const redirect = (sessionId) => {
  	stripe.redirectToCheckout({
  		sessionId: sessionId
  	})
  	.then(function (result) {
  	});
  };

  const handleGenerate=()=>{
  	const products = [{
  		price_data: {
  			currency: 'eur',
  			unit_amount: sstCost,
  			product_data: {
  				name: 'Speech-to-text',
  				description: 'Transcription generation',
  				images: ['https://toolboxai.app/images/toolboxAI.app.png'],
  			},
  		},
  		quantity: 1,
  	}]

// save data in the localStorage
  	localStorage.setItem('sst',JSON.stringify({email, fileName, filePath, outputFormat}))
  	createCheckoutSession(products)
  }



  return (
  	<section>
  	<Helmet>
  	<title>{metaTitle}</title>
  	<meta charSet="utf-8" />
  	<meta name="description" content={metaDescription} />
  	<meta name="keywords" content={metaKeywords} />
  	<meta name="viewport" content="width=device-width, initial-scale=1" />
  	<meta name="image" content="https://toolboxai.app/images/toolboxAI.app.png"/ >

  	<meta name="og:url" content="https://toolboxai.app/tts"/>
  	<meta name="og:type" content="website"/>
  	<meta name="og:image" content="https://toolboxai.app/images/toolboxAI.app.png"/>
  	<meta name="twitter:card" content="summary_large_image"/>
  	<meta name="twitter:image" content="https://toolboxai.app/images/toolboxAI.app.png"/>
  	</Helmet>

  	<article>
  	<h2>{title}</h2>
  	<p>{subtitle}</p>
  	<p>{description}</p>
  	<p>{provider}</p>
  	</article>
  	
  	<article>
  	<h4>{selectFile}</h4>
  	<p>{maxFileSize}</p>
  	<p>{supportedFormats}</p>

  	<details open={false}>
  	<summary>{supLangs}</summary>
  	<p>{supportedLanguages}</p>
  	</details>
  	<p>{costDescription.replace('{costPerMinute/100}',costPerMinute/100)}</p>
		{/* file upload form, supported formats of file are mp3, mp4, mpeg, mpga, m4a, wav, and webm */}
		{/* maximum filesize is 25mb */}
  	<input type="file" accept="audio/*" onChange={handleFileChange} />
  	{audioDuration>0&&<p>{durationOfAudio} {Math.floor(audioDuration/60)} {minutes} {Math.ceil(audioDuration%60)} {seconds}</p>}
  	</article>

  	<article>
  	<h4>{uploadFile}</h4>
  	<p>{uploadFileBeforePay}</p>
  	<button onClick={handleUpload}>Upload</button>
        {/* Display upload progress */}
  	{uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>}
  	</article>




  	<article>


  	<h4>{outputFormatTitle}</h4>
  	<p>{currentlySelectedOutputFormat} {outputFormat}</p>

  	<fieldset>		
  	<div className='fluidFlex'>
  	<span><input checked={outputFormat==='json'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="json" name="outputFormat" value="json" />
  	<label htmlFor="json">json</label></span>

  	<span><input checked={outputFormat==='text'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="text" name="outputFormat" value="text"/>
  	<label htmlFor="text">text</label></span>

  	<span><input checked={outputFormat==='srt'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="srt" name="outputFormat" value="srt"/>
  	<label htmlFor="srt">srt</label></span>

  	<span><input checked={outputFormat==='verbose_json'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="verbose_json" name="outputFormat" value="verbose_json"/>
  	<label htmlFor="verbose_json">verbose_json</label></span>

  	<span><input checked={outputFormat==='vtt'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="vtt" name="outputFormat" value="vtt"/>
  	<label htmlFor="vtt">vtt</label></span>

  	</div>
  	</fieldset>

  	</article>




  	<article>
  	<h4>{outputEmailTitle}</h4>
  	<p>{emailInstructions}</p>
  	<form className='flexColumn' onSubmit={(e)=>e.preventDefault()}>
  	<input 
  	onChange={(e)=>{setEmail(e.target.value)}} 
  	type="email" 
  	id="email" 
  	name="email" 
  	value={email} 
  	placeholder={emailPlaceholder}
  	/>
  	<input 
  	onChange={(e)=>{setEmailVer(e.target.value)}} 
  	type="email" 
  	id="email" 
  	name="emailVer" 
  	value={emailVer} 
  	placeholder={verifyEmailPlaceholder}
  	/>
  	{(email.length>0&&emailVer.length>0&&emailVer!==email) ? <p><span className='warning'>{emailWarning}</span></p>:null}
  	</form>
  	</article>


  	<article>
  	<h4>{transcribe}</h4>
  	<p>{transcribeInstructions}</p>
  	<p>{paymentInfo.replace('{sstCost/100}',sstCost/100)}</p> 
  	<p>{successfulCheckoutInfo}</p>
  	{(sstCost>0&&email.length>0&&email===emailVer)? <button onClick={handleGenerate}>{buttonText}</button>:<p>{uploadFilePrompt}</p>}

  	</article>


  	</section>



  	)
}