
export const ttsContent = {
	en: {
		metaTitle: 'TTS text-to-speech voiceover generator',
		metaDescription: 'Generate audio from text, text to speech, generate voiceover from text input',
		metaKeywords: 'tts, text-to-speech, generate audio, voice from text, text to voice',
		title:'TTS',
		subtitle:'Text-To-Speech',
		description:'It will generate an audio file with a voice out of your text.',
		provider:'Provider: OpenAI',
		enterYourText: '2. Enter your text',
		supLangs:'Supported languages',
		supportedLanguages: 'Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, Welsh',
		multiLangNotes:"You can generate spoken audio in these languages by providing the input text in the language of your choice.",
		costDescription: 'The cost of text-to-speech generator is {costPerMil/100}€ per 1000 characters. It rounds up. You will see the cost while you are typing before submitting.',
		characterConversionInfo: 'A 1000 characters is roughly around 1 minute of voiceover and mp3 file will be around 1 megabyte.',
		inputLength:'Your text input has {ttsText.length} characters.',
		costOfConversion:'Cost of conversion for the text entered: {ttsCost/100}€',
		selectVoiceTitle: '1. Select the voice',
		currentlySelectedVoice: 'Currently selected: ',
		outputFormatTitle: '3. Output format',
		currentlySelectedOutputFormat: 'Currently selected: ',
		outputEmailTitle: '4. Output email',
		emailInstructions: 'Please provide the email to send a link to the generated audio file. Make sure it\'s the correct email.',
		emailPlaceholder: 'Enter your email here',
		verifyEmailPlaceholder: 'Verify your email here',
		emailWarning: 'Warning: emails do not match',
		generateTitle: '5. Generate',
		generateInstructions: 'Click the button below to generate the audio file.',
		paymentInfo: 'You will be redirected to the secure payment processing page. Your total is {ttsCost/100}€.',
		successfulCheckoutInfo: 'Upon successful checkout, you will be redirected to the confirmation page where you will also be able to download the generated audio file.',
		buttonText: 'Pay and Generate',
		enterTextPrompt: 'Enter text to convert to voice in the previous steps.'
	},
	jp: {
    metaTitle: 'TTSテキスト読み上げ音声生成器',
    metaDescription: 'テキストからオーディオを生成し、テキスト読み上げ、テキスト入力から音声生成',
    metaKeywords: 'tts',
    title: 'TTS',
    subtitle: 'テキスト読み上げ',
    description: 'テキストから音声を生成します。',
    provider: '提供者：OpenAI',
    enterYourText: '二 テキストを入力',
    supLangs: 'サポートされている言語',
    supportedLanguages:  'アフリカーンス語, アラビア語, アルメニア語, アゼルバイジャン語, ベラルーシ語, ボスニア語, ブルガリア語, カタロニア語, 中国語, クロアチア語, チェコ語, デンマーク語, オランダ語, 英語, エストニア語, フィンランド語, フランス語, ガリシア語, ドイツ語, ギリシャ語, ヘブライ語, ヒンディー語, ハンガリー語, アイスランド語, インドネシア語, イタリア語, 日本語, カンナダ語, カザフ語, 韓国語, ラトビア語, リトアニア語, マケドニア語, マレー語, マラーティー語, マオリ語, ネパール語, ノルウェー語, ペルシャ語, ポーランド語, ポルトガル語, ルーマニア語, ロシア語, セルビア語, スロバキア語, スロベニア語, スペイン語, スワヒリ語, スウェーデン語, タガログ語, タミル語, タイ語, トルコ語, ウクライナ語, ウルドゥ語, ベトナム語, ウェールズ語',
    multiLangNotes: '選択した言語でテキスト入力を提供することで、これらの言語で音声を生成できます。',
    costDescription: 'テキスト読み上げジェネレータの費用は、1000文字あたり{costPerMil/100}€です。切り上げられます。タイプ中にコストが表示されます。',
    characterConversionInfo: '1000文字はおおよそ1分の音声に相当し、mp3ファイルはおおよそ1メガバイトになります。',
    inputLength: 'テキスト入力は{ttsText.length}文字です。',
    costOfConversion: '入力テキストの変換コスト：{ttsCost/100}€',
    selectVoiceTitle: '一 音声を選択',
    currentlySelectedVoice: '現在選択されています：',
    outputFormatTitle: '三 出力形式',
    currentlySelectedOutputFormat: '現在選択されています：',
    outputEmailTitle: '四 出力先メール',
    emailInstructions: '生成されたオーディオファイルへのリンクを送信するためのメールアドレスを入力してください。正しいメールアドレスであることを確認してください。',
    emailPlaceholder: 'メールアドレスをここに入力',
    verifyEmailPlaceholder: 'メールアドレスを確認',
    emailWarning: '警告：メールアドレスが一致しません',
    generateTitle: '五 生成',
    generateInstructions: '以下のボタンをクリックしてオーディオファイルを生成します。',
    paymentInfo: '安全な支払い処理ページにリダイレクトされます。合計金額は{ttsCost/100}€です。',
    successfulCheckoutInfo: '支払いが成功すると、生成されたオーディオファイルをダウンロードできる確認ページにリダイレクトされます。',
    buttonText: '支払いと生成',
    enterTextPrompt: '前のステップで音声に変換するテキストを入力してください。'
  },
  es: {
    metaTitle: 'Generador de voz en off de texto a voz TTS',
    metaDescription: 'Genera audio a partir de texto, texto a voz, genera locuciones a partir de texto',
    metaKeywords: 'tts, texto a voz, generar audio, voz a partir de texto, texto a voz',
    title: 'TTS',
    subtitle: 'Texto a Voz',
    description: 'Generará un archivo de audio con una voz a partir de su texto.',
    provider: 'Proveedor: OpenAI',
    enterYourText: '2. Ingresa tu texto',
    supLangs: 'Idiomas admitidos',
    supportedLanguages:  'Afrikáans, Árabe, Armenio, Azerbaiyano, Bielorruso, Bosnio, Búlgaro, Catalán, Chino, Croata, Checo, Danés, Holandés, Inglés, Estonio, Finlandés, Francés, Gallego, Alemán, Griego, Hebreo, Hindi, Húngaro, Islandés, Indonesio, Italiano, Japonés, Canarés, Kazajo, Coreano, Letón, Lituano, Macedonio, Malayo, Marathi, Maorí, Nepalí, Noruego, Persa, Polaco, Portugués, Rumano, Ruso, Serbio, Eslovaco, Esloveno, Español, Suajili, Sueco, Tagalo, Tamil, Tailandés, Turco, Ucraniano, Urdu, Vietnamita, Galés',
    multiLangNotes: 'Puede generar audio hablado en estos idiomas proporcionando el texto de entrada en el idioma que elija.',
    costDescription: 'El costo del generador de texto a voz es de {costPerMil/100}€ por cada 1000 caracteres. Se redondea hacia arriba. Verá el costo mientras escribe antes de enviar.',
    characterConversionInfo: '1000 caracteres son aproximadamente 1 minuto de locución y el archivo mp3 tendrá alrededor de 1 megabyte.',
    inputLength: 'Su entrada de texto tiene {ttsText.length} caracteres.',
    costOfConversion: 'Costo de conversión para el texto ingresado: {ttsCost/100}€',
    selectVoiceTitle: '1. Seleccionar la voz',
    currentlySelectedVoice: 'Actualmente seleccionado: ',
    outputFormatTitle: '3. Formato de salida',
    currentlySelectedOutputFormat: 'Actualmente seleccionado: ',
    outputEmailTitle: '4. Correo electrónico de salida',
    emailInstructions: 'Proporcione el correo electrónico para enviar un enlace al archivo de audio generado. Asegúrese de que sea el correo electrónico correcto.',
    emailPlaceholder: 'Ingrese su correo electrónico aquí',
    verifyEmailPlaceholder: 'Verifique su correo electrónico aquí',
    emailWarning: 'Advertencia: los correos electrónicos no coinciden',
    generateTitle: '5. Generar',
    generateInstructions: 'Haga clic en el botón a continuación para generar el archivo de audio.',
    paymentInfo: 'Será redirigido a la página segura de procesamiento de pagos. Su total es de {ttsCost/100}€.',
    successfulCheckoutInfo: 'Tras realizar el pago con éxito, se le redirigirá a la página de confirmación donde también podrá descargar el archivo de audio generado.',
    buttonText: 'Pagar y generar',
    enterTextPrompt: 'Ingrese texto para convertirlo a voz en los pasos anteriores.'
  },
  ru: {
    metaTitle: 'TTS: генератор аудио из текстаб текст вречь',
    metaDescription: 'Генерация аудио из текста, текст в речь, генерация озвучки из текстового ввода',
    metaKeywords: 'ttx, текст в речь, генерация аудио, голос из текста, текст в голос',
    title: 'TTS',
    subtitle: 'Текст в Речь',
    description: 'Это сгенерирует аудиофайл с голосом из вашего текста.',
    provider: 'Поставщик: OpenAI',
    enterYourText: '2. Введите свой текст',
    supLangs: 'Поддерживаемые языки',
    supportedLanguages: 'Африкаанс, Арабский, Армянский, Азербайджанский, Белорусский, Боснийский, Болгарский, Каталанский, Китайский, Хорватский, Чешский, Датский, Голландский, Английский, Эстонский, Финский, Французский, Галисийский, Немецкий, Греческий, Иврит, Хинди, Венгерский, Исландский, Индонезийский, Итальянский, Японский, Каннада, Казахский, Корейский, Латышский, Литовский, Македонский, Малайский, Маратхи, Маори, Непальский, Норвежский, Персидский, Польский, Португальский, Румынский, Русский, Сербский, Словацкий, Словенский, Испанский, Суахили, Шведский, Тагальский, Тамильский, Тайский, Турецкий, Украинский, Урду, Вьетнамский, Валлийский',
    multiLangNotes: 'Вы можете создавать звуковые аудио на этих языках, предоставив вводной текст на выбранном вами языке.',
    costDescription: 'Стоимость генератора текста в речь - {costPerMil/100}€ за 1000 символов. Она округляется вверх. Вы увидите стоимость при вводе перед отправкой.',
    characterConversionInfo: '1000 символов - примерно 1 минута голосовой озвучки, а файл mp3 будет размером около 1 мегабайта.',
    inputLength: 'Ваш ввод текста содержит {ttsText.length} символов.',
    costOfConversion: 'Стоимость конвертации для введенного текста: {ttsCost/100}€',
    selectVoiceTitle: '1. Выберите голос',
    currentlySelectedVoice: 'Текущий выбранный: ',
    outputFormatTitle: '3. Формат вывода',
    currentlySelectedOutputFormat: 'Текущий выбранный: ',
    outputEmailTitle: '4. Выходной адрес электронной почты',
    emailInstructions: 'Укажите адрес электронной почты для отправки ссылки на сгенерированный аудиофайл. Убедитесь, что это правильный адрес электронной почты.',
    emailPlaceholder: 'Введите свой адрес электронной почты здесь',
    verifyEmailPlaceholder: 'Подтвердите свой адрес электронной почты здесь',
    emailWarning: 'Предупреждение: адреса электронной почты не совпадают',
    generateTitle: '5. Создать',
    generateInstructions: 'Нажмите кнопку ниже, чтобы создать аудиофайл.',
    paymentInfo: 'Вы будете перенаправлены на безопасную страницу обработки платежей. Ваш общий счет - {ttsCost/100}€.',
    successfulCheckoutInfo: 'После успешной оплаты вы будете перенаправлены на страницу подтверждения, где вы также сможете скачать сгенерированный аудиофайл.',
    buttonText: 'Оплатить и создать',
    enterTextPrompt: 'Введите текст для преобразования в голос в предыдущих шагах.'
  },
  zh: {
    metaTitle: 'TTS文本转语音配音生成器',
    metaDescription: '从文本生成音频，文本转语音，从文本输入生成配音',
    metaKeywords: 'tts, 文本转语音, 生成音频, 文本语音, 文本到语音',
    title: 'TTS',
    subtitle: '文本到语音',
    description: '它将从您的文本生成带有语音的音频文件。',
    provider: '提供商：OpenAI',
    enterYourText: '2. 输入您的文本',
    supLangs: '支持的语言',
    supportedLanguages: '南非荷兰语, 阿拉伯语, 亚美尼亚语, 阿塞拜疆语, 白俄罗斯语, 波斯尼亚语, 保加利亚语, 加泰罗尼亚语, 中文, 克罗地亚语, 捷克语, 丹麦语, 荷兰语, 英语, 爱沙尼亚语, 芬兰语, 法语, 加利西亚语, 德语, 希腊语, 希伯来语, 印地语, 匈牙利语, 冰岛语, 印度尼西亚语, 意大利语, 日语, 卡纳达语, 哈萨克语, 韩语, 拉脱维亚语, 立陶宛语, 马其顿语, 马来语, 马拉地语, 毛利语, 尼泊尔语, 挪威语, 波斯语, 波兰语, 葡萄牙语, 罗马尼亚语, 俄语, 塞尔维亚语, 斯洛伐克语, 斯洛文尼亚语, 西班牙语, 斯瓦希里语, 瑞典语, 他加禄语, 泰米尔语, 泰语, 土耳其语, 乌克兰语, 乌尔都语, 越南语, 威尔士语',
    multiLangNotes: '通过以您选择的语言提供输入文本，您可以生成这些语言的口头语音。',
    costDescription: '文本到语音生成器的成本为每1000个字符{costPerMil/100}€。 它向上舍入。 在提交之前，您将在键入时看到成本。',
    characterConversionInfo: '1000个字符大约是1分钟的配音，mp3文件大小约为1兆字节。',
    inputLength: '您的文本输入包含{ttsText.length}个字符。',
    costOfConversion: '输入文本的转换成本：{ttsCost/100}€',
    selectVoiceTitle: '1. 选择语音',
    currentlySelectedVoice: '当前选择：',
    outputFormatTitle: '3. 输出格式',
    currentlySelectedOutputFormat: '当前选择：',
    outputEmailTitle: '4. 输出电子邮件',
    emailInstructions: '请提供电子邮件以发送生成的音频文件的链接。 请确保是正确的电子邮件。',
    emailPlaceholder: '在此处输入您的电子邮件',
    verifyEmailPlaceholder: '在此处验证您的电子邮件',
    emailWarning: '警告：电子邮件不匹配',
    generateTitle: '5. 生成',
    generateInstructions: '单击下面的按钮生成音频文件。',
    paymentInfo: '您将被重定向到安全的支付处理页面。 您的总计为{ttsCost/100}€。',
    successfulCheckoutInfo: '成功结账后，您将被重定向到确认页面，您还可以下载生成的音频文件。',
    buttonText: '支付并生成',
    enterTextPrompt: '在前面的步骤中输入要转换为语音的文本。'
  },
  ar: {
    metaTitle: 'مولد صوتي نصي إلى كلام',
    metaDescription: 'إنشاء صوت من النص، نص إلى كلام، إنشاء تسجيل صوتي من إدخال النص',
    metaKeywords: 'tts، نص إلى كلام، إنشاء صوتي، صوت من النص، نص إلى صوت',
    title: 'TTS',
    subtitle: 'نص إلى كلام',
    description: 'سيقوم بإنشاء ملف صوتي بصوت من النص الخاص بك.',
    provider: 'المزود: OpenAI',
    enterYourText: '2. أدخل نصك',
    supLangs: 'اللغات المدعومة',
    supportedLanguages: 'الأفريكانية, العربية, الأرمينية, الأذربيجانية, البيلاروسية, البوسنية, البلغارية, الكاتالونية, الصينية, الكرواتية, التشيكية, الدانماركية, الهولندية, الإنجليزية, الإستونية, الفنلندية, الفرنسية, الجاليكية, الألمانية, اليونانية, العبرية, الهندية, الهنغارية, الأيسلندية, الإندونيسية, الإيطالية, اليابانية, الكانادا, الكازاخستانية, الكورية, اللاتفية, الليتوانية, المقدونية, الماليزية, الماراثية, الماورية, النيبالية, النرويجية, الفارسية, البولندية, البرتغالية, الرومانية, الروسية, الصربية, السلوفاكية, السلوفينية, الإسبانية, السواحيلية, السويدية, التاغالوغية, التاميلية, التايلاندية, التركية, الأوكرانية, الأردية, الفيتنامية, الويلزية',
    multiLangNotes: 'يمكنك إنشاء ملف صوتي في هذه اللغات عن طريق توفير النص الإدخالي باللغة التي تختارها.',
    costDescription: 'تكلفة محول النص إلى كلام هي {costPerMil/100}€ لكل 1000 حرف. تُستدرج لأعلى. سترى التكلفة أثناء الكتابة قبل الإرسال.',
    characterConversionInfo: '1000 حرف تقريبًا هو حوالي دقيقة واحدة من الدبلجة الصوتية وسيكون حجم ملف mp3 حوالي 1 ميغابايت.',
    inputLength: 'إدخال النص الخاص بك يحتوي على {ttsText.length} حرفًا.',
    costOfConversion: 'تكلفة تحويل النص المدخل: {ttsCost/100}€',
    selectVoiceTitle: '1. حدد الصوت',
    currentlySelectedVoice: 'المحدد حاليًا: ',
    outputFormatTitle: '3. تنسيق الإخراج',
    currentlySelectedOutputFormat: 'المحدد حاليًا: ',
    outputEmailTitle: '4. البريد الإلكتروني الناتج',
    emailInstructions: 'يرجى تقديم البريد الإلكتروني لإرسال رابط للملف الصوتي المُنشأ. تأكد من أنه البريد الإلكتروني الصحيح.',
    emailPlaceholder: 'أدخل بريدك الإلكتروني هنا',
    verifyEmailPlaceholder: 'تحقق من بريدك الإلكتروني هنا',
    emailWarning: 'تحذير: البريد الإلكتروني غير متطابق',
    generateTitle: '5. إنشاء',
    generateInstructions: 'انقر على الزر أدناه لإنشاء ملف الصوت.',
    paymentInfo: 'ستُعاد توجيهك إلى صفحة معالجة الدفع الآمنة. إجماليك هو {ttsCost/100}€.',
    successfulCheckoutInfo: 'عند إتمام الدفع بنجاح، ستُعاد توجيهك إلى صفحة التأكيد حيث يمكنك أيضًا تنزيل الملف الصوتي المُنشأ.',
    buttonText: 'ادفع وأنشئ',
    enterTextPrompt: 'أدخل النص لتحويله إلى صوت في الخطوات السابقة.'
  },
};
