import axios from '../configs/axios.js'

const GenerateSST=async ({email,fileName,filePath,outputFormat})=>{
	const data={fileName,response_format:outputFormat, email, filePath}
	try{
		let generated = await axios.post('/services/sst',data)
		console.log(generated)
		return generated
	}catch(e){

	}

}
export {GenerateSST}