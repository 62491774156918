import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import TTS from '../services/tts'
import SST from '../services/sst'
import VTT from '../services/vtt'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Stripe = (props) => {
	return (

			<Elements stripe={stripePromise}>
			{props.service==='tts' && <TTS {...props} />}
			{props.service==='sst' && <SST {...props} />}
			{props.service==='vtt' && <VTT {...props} />}
			</Elements>

	);
};

export default Stripe;