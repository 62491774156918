// email, selectedFile, outputFormat
import axios from '../configs/axios.js'

const GenerateVTT=async ({email, selectedFile, outputFormat})=>{
	const data={email, selectedFile, outputFormat}
	try{
		let generated = await axios.post('/services/vtt',data)
		console.log(generated)
		return generated
	}catch(e){

	}

}
export {GenerateVTT}