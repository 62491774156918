import React from 'react'
import './Footer.css'
export default function Footer() {
	return (
		<footer>
		{/*<hr/>*/}
		<section>
		<h2>toolboxAI.app</h2>
			<p>Built by humans at <a href="https://barcelonacodeschool.com" target="_blank">Barcelona&nbsp;Code&nbsp;School</a> / <a href="http://undefeatedsoftware.dev" target="_blank">Undefeated&nbsp;Software</a></p>
			<p>Got questions? Need help? Want to say thanks – <a href="mailto:office@barcelonacodeschool.com">we love emails</a>.</p>
			<p>質問がありますか？助けが必要ですか？お礼を言いたいですか？ – <a href="mailto:office@barcelonacodeschool.com">メールが大好きです</a>。</p>
			<p>{(new Date()).toLocaleString('default', { month: 'long' })} {(new Date()).getFullYear()} / v3.3.2</p>
			</section>
		</footer>
	)
}
