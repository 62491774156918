export const homepageContent = {
  en: {
    title: 'toolboxAI.app',
    slogan: 'AI productivity tools available on demand',
  },
  jp: {
    title: 'toolboxAI.app',
    slogan: 'AIツールのセット、リクエストに応じて利用可能',
  },
  es: {
    title: 'toolboxAI.app',
    slogan: 'Herramientas de productividad de IA disponibles a pedido',
  },
  ru: {
    title: 'toolboxAI.app',
    slogan: 'Инструменты искусственного интеллекта доступны по запросу',
  },
  zh: {
    title: 'toolboxAI.app',
    slogan: 'AI生产力工具随需而用',
  },
  ar: {
    title: 'toolboxAI.app',
    slogan: 'أدوات الذكاء الاصطناعي للإنتاجية متاحة حسب الطلب',
  },
};
