import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {useStripe} from '@stripe/react-stripe-js';
import axios from '../configs/axios.js'
import Helmet from 'react-helmet';
import {useRecoilValue} from 'recoil'
import { activeLanguageState } from '../state.js'
import {vttContent} from '../content/vttContent.js'

export default function Vtt() {
	const activeLanguage = useRecoilValue(activeLanguageState)

	const {metaTitle, metaDescription, metaKeywords, title, subtitle, description, provider, selectFile, maxFileSize, supportedFormats, costDescription, durationOfAudio, minutes, seconds, uploadFile, uploadFileBeforePay, transcribe, outputEmailTitle, emailInstructions, emailPlaceholder, verifyEmailPlaceholder, emailWarning, supLangs, supportedLanguages, 	transcribeInstructions, paymentInfo, successfulCheckoutInfo, buttonText, uploadFilePrompt, outputFormatTitle, currentlySelectedOutputFormat, analyzeBtn, vttPrice } = vttContent[activeLanguage]

	const [vttCost, setVttCost] = useState(null)
	const costPerMinute = 99 // cents
	const [selectedFile, setSelectedFile] = useState('');
	const [audioDuration, setAudioDuration] = useState(null);
	const [durationInMinutes, setDurationInMinutes]=useState(0)
	const [durationInSeconds, setDurationInSeconds]=useState(0)

	const analyzeVideo =async ()=> {
		const videoMeta = await axios.post('/services/vtt/analyzevideo', {video:selectedFile});
		setDurationInMinutes(videoMeta.data.durationMinutes)
		setDurationInSeconds(videoMeta.data.durationSeconds)

		// videoMeta.data.durationMinutes*60+videoMeta.data.durationSeconds
		setAudioDuration(videoMeta.data.totalMinutes)
	}


	useEffect(()=>{
		let actualCost = Math.ceil(audioDuration*costPerMinute)
		if(actualCost<99){
			actualCost=99
		}
		setVttCost(actualCost)
	},[audioDuration])

	const [email, setEmail] = useState('')
	const [emailVer, setEmailVer] = useState('')
	const [outputFormat, setOutputFormat] = useState('')







	const navigate = useNavigate();
	const stripe = useStripe();

	const createCheckoutSession = async (products) => {
		try {
			const response = await axios.post(`/payment/vtt/create-checkout-session`, { products });
			return response.data.ok
			? (localStorage.setItem('sessionId', JSON.stringify(response.data.sessionId)),
				redirect(response.data.sessionId))
			: navigate('/payment/error');
		} catch (error) {
			navigate('/payment/error');
		}
	};

	const redirect = (sessionId) => {
		stripe.redirectToCheckout({
			sessionId: sessionId
		})
		.then(function (result) {
		});
	};

	const handleGenerate=()=>{
		const products = [{
			price_data: {
				currency: 'eur',
				unit_amount: vttCost,
				product_data: {
					name: 'Video to text',
					description: 'Transcription generation',
					images: ['https://toolboxai.app/images/toolboxAI.app.png'],
				},
			},
			quantity: 1,
		}]

// save data in the localStorage
		localStorage.setItem('vtt',JSON.stringify({email, selectedFile, outputFormat}))
		createCheckoutSession(products)
	}



	return (
		<section>
		<Helmet>
		<title>{metaTitle}</title>
		<meta charSet="utf-8" />
		<meta name="description" content={metaDescription} />
		<meta name="keywords" content={metaKeywords} />
		<meta name="viewport" content="width=device-width, initial-scale=1" />
		<meta name="image" content="https://toolboxai.app/images/toolboxAI.app.png"/ >

		<meta name="og:url" content="https://toolboxai.app/tts"/>
		<meta name="og:type" content="website"/>
		<meta name="og:image" content="https://toolboxai.app/images/toolboxAI.app.png"/>
		<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:image" content="https://toolboxai.app/images/toolboxAI.app.png"/>
		</Helmet>

		<article>
		<h2>{title}</h2>
		<p>{subtitle}</p>
		<p>{description}</p>
		<p>{provider}</p>
		</article>

		<article>
		<h4>{selectFile}</h4>
		<p>{maxFileSize}</p>
		<p>{supportedFormats}</p>

		<details open={false}>
		<summary>{supLangs}</summary>
		<p>{supportedLanguages}</p>
		</details>
		<p>{costDescription.replace('{costPerMinute/100}',costPerMinute/100)}</p>
		{/* file upload form, supported formats of file are mp3, mp4, mpeg, mpga, m4a, wav, and webm */}
		{/* maximum filesize is 25mb */}
		<input type='text' placeholder='Please provide a link to your video' className='fullWidthInput' value={selectedFile} onChange={(e)=>setSelectedFile(e.target.value)} />
		{selectedFile&&<p className='wordBreak'>You file is: {selectedFile}</p>}
		<button onClick={analyzeVideo}>{analyzeBtn}</button>
		<p>{durationOfAudio} {durationInMinutes} {minutes} {durationInSeconds} {seconds}</p>
		{audioDuration&&<p>{vttPrice.replace('{vttCost/100}', vttCost/100)}€.</p>}
		</article>


		<article>
{/*	response_format
	string
	Optional
	Defaults to json
	The format of the transcript output, in one of these options: json, text, srt, verbose_json, or vtt*/}

		<h4>{outputFormatTitle}</h4>
		<p>{currentlySelectedOutputFormat} {outputFormat}</p>

		<fieldset>		
		<div className='fluidFlex'>
		<span><input checked={outputFormat==='json'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="json" name="outputFormat" value="json" />
		<label htmlFor="json">json</label></span>

		<span><input checked={outputFormat==='text'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="text" name="outputFormat" value="text"/>
		<label htmlFor="text">text</label></span>

		<span><input checked={outputFormat==='srt'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="srt" name="outputFormat" value="srt"/>
		<label htmlFor="srt">srt</label></span>

		<span><input checked={outputFormat==='verbose_json'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="verbose_json" name="outputFormat" value="verbose_json"/>
		<label htmlFor="verbose_json">verbose_json</label></span>

		<span><input checked={outputFormat==='vtt'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="vtt" name="outputFormat" value="vtt"/>
		<label htmlFor="vtt">vtt</label></span>
		</div>
		</fieldset>
		</article>




		<article>
		<h4>{outputEmailTitle}</h4>
		<p>{emailInstructions}</p>
		<form className='flexColumn' onSubmit={(e)=>e.preventDefault()}>
		<input 
		onChange={(e)=>{setEmail(e.target.value)}} 
		type="email" 
		id="email" 
		name="email" 
		value={email} 
		placeholder={emailPlaceholder}
		/>
		<input 
		onChange={(e)=>{setEmailVer(e.target.value)}} 
		type="email" 
		id="email" 
		name="emailVer" 
		value={emailVer} 
		placeholder={verifyEmailPlaceholder}
		/>
		{(email.length>0&&emailVer.length>0&&emailVer!==email) ? <p><span className='warning'>{emailWarning}</span></p>:null}
		</form>
		</article>


		<article>
		<h4>{transcribe}</h4>
		<p>{transcribeInstructions}</p>
		<p>{paymentInfo.replace('{vttCost/100}',audioDuration>0?vttCost/100:0)}</p> 
		<p>{successfulCheckoutInfo}</p>
		{(vttCost>0&&email.length>0&&email===emailVer)? <button onClick={handleGenerate}>{buttonText}</button>:<p>{uploadFilePrompt}</p>}

		</article>


		</section>



		)
}