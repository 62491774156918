export const navbarContent = {
  en: {
    about: 'About service',
    tts: 'Text-to-speech',
    sst: 'Audio to text',
    vidToText: 'Video to text',
  },
  jp: {
    about: 'サービスについて',
    tts: 'TTS / テキスト読み上げ',
    sst: '音声からテキストへ',
    vidToText: 'ビデオからテキストへ',
  },
  es: {
    about: 'Acerca del servicio',
    tts: 'Texto a voz',
    sst: 'De audio a texto',
    vidToText: 'Video a texto',
  },
  ru: {
    about: 'О сервисе',
    tts: 'Текст в речь',
    sst: 'Аудио в текст',
    vidToText: 'Видео в текст',
  },
  zh: {
    about: '关于服务',
    tts: '文本转语音',
    sst: '语音转文字',
    vidToText: '视频转文字',
  },
  ar: {
    about: 'حول الخدمة',
    tts: 'نص إلى كلام',
    sst: 'صوت إلى نص',
    vidToText: 'فيديو إلى نص',
  },
};
