import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {useStripe} from '@stripe/react-stripe-js';
import axios from '../configs/axios.js'
import Helmet from 'react-helmet';
import {useRecoilValue} from 'recoil'
import { activeLanguageState } from '../state.js'
import Slider from '../components/Slider.js'

import {ttsContent} from '../content/tts.js'
import '../CSS/audioPlayer.css'



export default function Tts() {



	const activeLanguage = useRecoilValue(activeLanguageState)

	const [activeVoice, setActiveVoice] = useState('Alloy')
	const [ttsText, setTtsText] = useState('')
	const [ttsCost, setTtsCost] = useState(0)
	const [email, setEmail] = useState('')
	const [emailVer, setEmailVer] = useState('')
	const [outputFormat, setOutputFormat] = useState('mp3')
	const [speed, setSpeed] = useState(1)
	const costPerMil = 99 // cents

	// to API: 
	// activeVoice
	// ttsText
	// email
	// outputFormat

	useEffect(()=>{
		setTtsCost(Math.ceil(ttsText.length/1000) * costPerMil)
	},[ttsText])


	const {metaTitle, metaDescription, metaKeywords, title, subtitle, description, provider, enterYourText, supportedLanguages, multiLangNotes, costDescription, characterConversionInfo, selectVoiceTitle, currentlySelectedVoice, outputFormatTitle, currentlySelectedOutputFormat, outputEmailTitle, emailInstructions, emailPlaceholder, verifyEmailPlaceholder, emailWarning, generateTitle, generateInstructions, paymentInfo, successfulCheckoutInfo, buttonText, enterTextPrompt,supLangs,inputLength,costOfConversion} = ttsContent[activeLanguage]


	const navigate = useNavigate();
	const stripe = useStripe();

	const createCheckoutSession = async (products) => {
		try {
			const response = await axios.post(`/payment/tts/create-checkout-session`, { products });
			return response.data.ok
			? (localStorage.setItem('sessionId', JSON.stringify(response.data.sessionId)),
				redirect(response.data.sessionId))
			: navigate('/payment/error');
		} catch (error) {
			navigate('/payment/error');
		}
	};

	const redirect = (sessionId) => {
		stripe.redirectToCheckout({
			sessionId: sessionId
		})
		.then(function (result) {
		});
	};

	const handleGenerate=()=>{
		const products = [{
			price_data: {
				currency: 'eur',
				unit_amount: ttsCost,
				product_data: {
					name: 'Text-to-speech',
					description: 'Voiceover generation',
					images: ['https://toolboxai.app/images/toolboxAI.app.png'],
				},
			},
			quantity: 1,
		}]

// save data in the localStorage
		localStorage.setItem('tts',JSON.stringify({activeVoice, ttsText, speed, email, outputFormat}))
		createCheckoutSession(products)
	}

	return (
		<section>
		<Helmet>
		<title>{metaTitle}</title>
		<meta charSet="utf-8" />
		<meta name="description" content={metaDescription} />
		<meta name="keywords" content={metaKeywords} />
		<meta name="viewport" content="width=device-width, initial-scale=1" />
		<meta name="image" content="https://toolboxai.app/images/toolboxAI.app.png"/ >

		<meta name="og:url" content="https://toolboxai.app/tts"/>
		<meta name="og:type" content="website"/>
		<meta name="og:image" content="https://toolboxai.app/images/toolboxAI.app.png"/>
		<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:image" content="https://toolboxai.app/images/toolboxAI.app.png"/>


		</Helmet>

		<article>
		<h2>{title}</h2>
		<p>{subtitle}</p>
		<p>{description}</p>
		<p>{provider}</p>
		</article>

		<article>

		<h4>{selectVoiceTitle}</h4>
		<p>{currentlySelectedVoice} {activeVoice}</p>

		<fieldset>
		
		<div className='fluidFlex'>
		<div className='audioContainer'>
		<div>
		<input checked={activeVoice==='Alloy'?true:false} onChange={(e)=>setActiveVoice(e.target.value)} type="radio" id="Alloy" name="activeVoice" value="Alloy" />
		<label htmlFor="Alloy">Alloy</label>
		</div>
		
		<audio controls src="https://cdn.openai.com/API/docs/audio/alloy.wav"></audio>
		</div>

		<div className='audioContainer'>
		<div>
		<input checked={activeVoice==='Echo'?true:false} onChange={(e)=>setActiveVoice(e.target.value)} type="radio" id="Echo" name="activeVoice" value="Echo"/>
		<label htmlFor="Echo">Echo</label>
		</div>

		<audio controls src="https://cdn.openai.com/API/docs/audio/echo.wav"></audio>
		
		</div>
		
		<div className='audioContainer'>
		<div>
		<input checked={activeVoice==='Fable'?true:false} onChange={(e)=>setActiveVoice(e.target.value)} type="radio" id="Fable" name="activeVoice" value="Fable"/>

		<label htmlFor="Fable">Fable</label>
		</div>
		<audio controls src="https://cdn.openai.com/API/docs/audio/fable.wav"></audio>
		</div>
		
		<div className='audioContainer'>
		<div>
		<input checked={activeVoice==='Onyx'?true:false} onChange={(e)=>setActiveVoice(e.target.value)} type="radio" id="Onyx" name="activeVoice" value="Onyx"/>
		<label htmlFor="Onyx">Onyx</label>
		</div>
		<audio controls src="https://cdn.openai.com/API/docs/audio/onyx.wav"></audio>
		
		</div>
		<div className='audioContainer'>
		<div>
		<input checked={activeVoice==='Nova'?true:false} onChange={(e)=>setActiveVoice(e.target.value)} type="radio" id="Nova" name="activeVoice" value="Nova"/>
		<label htmlFor="Nova">Nova</label>
		</div>
		<audio controls src="https://cdn.openai.com/API/docs/audio/nova.wav"></audio>
		
		</div>
		<div className='audioContainer'>
		<div>
		<input checked={activeVoice==='Shimmer'?true:false} onChange={(e)=>setActiveVoice(e.target.value)} type="radio" id="Shimmer" name="activeVoice" value="Shimmer"/>
		<label htmlFor="Shimmer">Shimmer</label>
		</div>
		<audio controls src="https://cdn.openai.com/API/docs/audio/shimmer.wav"></audio>
		
		</div>
		</div>
		</fieldset>
		<audio src="https://cdn.openai.com/API/docs/audio/alloy.wav"></audio>
		<figure>
		</figure>

		<Slider setSpeed={setSpeed}/>

		</article>

		<article>
		<h4>{enterYourText}</h4>

		<details open={false}>
		<summary>{supLangs}</summary>
		<p>{supportedLanguages}</p>
		<p>{multiLangNotes}</p>
		</details>

		<p>{costDescription.replace('{costPerMil/100}',costPerMil/100)}</p>
		<p>{characterConversionInfo}</p>
		<textarea className='ttsInputText' onChange={(e)=>setTtsText(e.target.value)} id="textToConvert" name="textToConvert" rows="10" placeholder="Enter your text here"></textarea>
		
		{ttsText.length>0 && 
		<>
		<p>{inputLength.replace('{ttsText.length}',ttsText.length)}</p>
		<p>{costOfConversion.replace('{ttsCost/100}',ttsCost/100)}</p>
		</>
	}
	</article>


	<article>

	<h4>{outputFormatTitle}</h4>
	<p>{currentlySelectedOutputFormat} {outputFormat}</p>

	<fieldset>		
	<div className='fluidFlex'>
	<input checked={outputFormat==='mp3'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="mp3" name="outputFormat" value="mp3" />
	<label htmlFor="mp3">mp3</label>

	<input checked={outputFormat==='opus'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="opus" name="outputFormat" value="opus"/>
	<label htmlFor="opus">Opus</label>

	<input checked={outputFormat==='aac'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="aac" name="outputFormat" value="aac"/>
	<label htmlFor="aac">AAC</label>

	<input checked={outputFormat==='flac'?true:false} onChange={(e)=>setOutputFormat(e.target.value)} type="radio" id="flac" name="outputFormat" value="flac"/>
	<label htmlFor="flac">FLAC</label>

	</div>
	</fieldset>

	</article>


	<article>
	<h4>{outputEmailTitle}</h4>
	<p>{emailInstructions}</p>
	<form className='flexColumn' onSubmit={(e)=>e.preventDefault()}>
	<input 
	onChange={(e)=>{setEmail(e.target.value)}} 
	type="email" 
	id="email" 
	name="email" 
	value={email} 
	placeholder={emailPlaceholder}
	/>
	<input 
	onChange={(e)=>{setEmailVer(e.target.value)}} 
	type="email" 
	id="email" 
	name="emailVer" 
	value={emailVer} 
	placeholder={verifyEmailPlaceholder}
	/>
	{(email.length>0&&emailVer.length>0&&emailVer!==email) ? <p><span className='warning'>{emailWarning}</span></p>:null}
	</form>
	</article>


	<article>
	<h4>{generateTitle}</h4>
	<p>{generateInstructions}</p>
	<p>{paymentInfo.replace('{ttsCost/100}',ttsCost/100)}</p> 
	<p>{successfulCheckoutInfo}</p>
	{(ttsCost>0&&email.length>0&&email===emailVer)? <button onClick={handleGenerate}>{buttonText}</button>:<p>{enterTextPrompt}</p>}
	</article>

	</section>

	)
}