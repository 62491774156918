import React, { useEffect,useState } from "react";
import axios from "../configs/axios.js";
import Scroller from '../components/Scroller.js'
import {GenerateTTS} from '../utils/GenerateTTS.js'
import { useNavigate } from 'react-router-dom';
import {useRecoilValue} from 'recoil'
import { activeLanguageState } from '../state.js'
import {ttsSuccess} from '../content/ttsSuccess.js'

const PaymentSuccess = (props) => {
  const activeLanguage = useRecoilValue(activeLanguageState)
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('tts')))
  const [fileURL,setFileURL]=useState(null)
  const navigate = useNavigate();

  useEffect(()=>{
    // activeVoice: "Alloy"
    // email: "vivobcn@gmail.com"
    // outputFormat: "mp3"
    // ttsText: "vivobcn@gmail.com"

  },[userData])

  const {title,voiceover,check,link,
  download,expire,thanks}=ttsSuccess[activeLanguage]

  useEffect(() => {
    const getSessionData = async () => {
      
      try {
        const sessionId = JSON.parse(localStorage.getItem("sessionId"));
        const response = await axios.get(
          `/payment/tts/checkout-session?sessionId=${sessionId}`
          );
        localStorage.removeItem("sessionId");
        const {activeVoice, email:emailTo, outputFormat, ttsText,speed}=userData
        // get the customer details after the payment, ready to generate
        const {email,name,ok,payment_intent,status }=response.data
        // GenerateTTS(email,name,ok,payment_intent,status)
        const genResponse = await GenerateTTS(email,name,ok,payment_intent,status, ttsText, emailTo, outputFormat, activeVoice, speed)
        setFileURL(genResponse.data.data)
        setLoading(false);
        localStorage.clear()

      } catch (error) {
        //handle the error here, in case of network error
        alert("Something is wrong.")
        navigate('/')
      }
    };
    getSessionData();
  }, []);

  return (
    <section>
    {loading?
    <>
    <h1>Payment successful. ありがとう</h1>
    <p>Processing your request</p>
    <Scroller/>
    </>:
    <article>
    <h1>{title}</h1>
    <p>{voiceover.replace('email@email.com',userData.email)}</p> 
    <p>{check}</p>
    <p><a href={fileURL} target='_blank'>{link}</a></p>
    <p>{download}</p>
    <p><span className='attention'>{expire}</span></p>
    <p>{thanks}</p>
    <p>サービスをご利用いただきありがとうございます！</p>
    </article>}
    </section>
    );
};

export default PaymentSuccess;
