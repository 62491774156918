export const sliderContent = {
  en: {
    speed: 'Voices speed (default 1):',
    buttonLabel: 'Reset',
  },
  jp: {
    speed: '音声の速さ（デフォルト1）：',
    buttonLabel: 'リセット',
  },
  es: {
    speed: 'Velocidad de las voces (predeterminada 1):',
    buttonLabel: 'Restablecer',
  },
  ru: {
    speed: 'Скорость голосов (по умолчанию 1):',
    buttonLabel: 'Сброс',
  },
  zh: {
    speed: '语音速度（默认1）：',
    buttonLabel: '重置',
  },
  ar: {
    speed: 'سرعة الأصوات (الافتراضية 1):',
    buttonLabel: 'إعادة تعيين',
  },
};
