import React from 'react'
import loader from '../assets/loader.gif'

export default function Scroller() {
	return (
		<div>
			<img style={styles.loader}src={loader} alt='loader'/>
		</div>
	)
}
const styles = {
	loader:{
		display:'block',
		margin:'0 auto'
	}
}