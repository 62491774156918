import React, {useState} from 'react'
import {serviceDescriptionContent} from "../content/serviceDescription.js"
import { useRecoilValue } from 'recoil'
import { activeLanguageState } from '../state.js'

const ServiceDescription=()=> {
const activeLanguage = useRecoilValue(activeLanguageState)
	return (
		<section>
		<article>
{/*		<details open>
		<summary>{serviceDescriptionContent[activeLanguage].title} / 私たちのサービスについて</summary>

		{serviceDescriptionContent[activeLanguage].description.map((item,index)=>(
			<p key={index}>{item}</p>
			))}
		
		</details>*/}

		<p>{serviceDescriptionContent[activeLanguage].title} / 私たちのサービスについて</p>

		{serviceDescriptionContent[activeLanguage].description.map((item,index)=>(
			<p className='serviceDescriptionP' key={index}>{item}</p>
			))}
		
</article>
		</section>
		)
}

export default ServiceDescription